import BigNumber from "bignumber.js";
import { useGetProposal, useGetVoters } from "clients/api";
import {
  imgBackPattern,
  imgBackPatternMobile,
  imgArrowRight,
  imgArrowLeft,
  imgUser,
} from "components/images";
import ProgressBar from "components/progressbar";
import { ReadableActionSignature } from "components/ReadableActionSignature";
import Stepper from "components/stepper";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { shortenValueWithSuffix, truncateAddress } from "utilities";

const Container = styled.div`
  position: relative;
  background: #fcfcfc;
`;

const BannerContainer = styled.div`
  background-color: #161a1f;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 589px;

  @media only screen and (max-width: 768px) {
    padding: 0px 50px;
    height: 100vh;
  }

  @media only screen and (max-width: 480px) {
    padding: 0px 24px;
  }
`;

const BannerBackPattern = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  max-width: 959px;
  width: 100%;

  .mobileImg {
    display: none;
  }

  img {
    width: 100%;
    object-position: 0px -100px;
  }

  @media only screen and (max-width: 768px) {
    .desktopImg {
      display: none;
    }

    .mobileImg {
      display: block;
    }

    width: 260vw;
    height: 80vh;
    left: -80vw;
    img {
      width: 260vw;
    }
  }
`;

const Content = styled.div`
  position: relative;
  padding: 150px 156px;
  z-index: 10;

  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .back {
      display: flex;
      gap: 18px;
    }

    .userInfo {
      display: flex;
      align-items: center;
      gap: 20px;

      .userImg {
        background-color: white;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .title {
    font-size: 35px;
  }

  .status {
    margin-top: 21px;
    font-size: 20px;
  }

  .date {
    margin-top: 16px;
    font-size: 16px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 150px 50px;
  }

  @media only screen and (max-width: 768px) {
    padding: 150px 35px 150px 35px;

    .topBar {
      margin-bottom: 70px;
    }

    .title {
      font-size: 35px;
    }
  }
`;

const VoteStatus = styled.div`
  margin-top: 94px;
  display: flex;
  justify-content: space-between;
  gap: 50px;

  .content {
    position: relative;
    width: 100%;
    padding: 42px 39px 42px 33px;
    border-radius: 7px;
    box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #e3e3e3;
    background-color: white;
    color: #20252d;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      .totalWeight {
        font-family: Roboto-Bold;
      }
    }

    .divider {
      margin-top: 12px;
      border: solid 1px #eaeaea;
    }

    .progressBar {
      margin: 29px 0px 47px 0px;
    }

    .user_list {
      margin: 42px 0px 48px 0px;
      display: flex;
      flex-direction: column;
      gap: 18px;

      a {
        color: #31d8ff;
      }
    }

    .viewAll {
      position: absolute;
      bottom: 35px;
      right: 39px;
      display: flex;
      align-items: center;
      justify-content: end;
      font-size: 16px;
      gap: 22px;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProposalDetail = styled.div`
  margin-top: 33px;
  display: flex;
  justify-content: space-between;
  gap: 25px;
  color: #20252d;

  .title {
    font-family: Roboto-Bold;
    font-size: 25px;
    color: #20252d;
  }

  .sub-title {
    font-family: Roboto-Bold;
    font-size: 16px;
  }

  .divider {
    margin: 25px 0px;
    border: solid 1px #eaeaea;
  }

  .proposalDetail {
    width: 100%;
    padding: 49px 24px 80px 24px;
    box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #e3e3e3;
    word-break: break-all;
  }

  .proposalHistory {
    min-width: 395px;
    padding: 49px 24px 73px 24px;
    border-radius: 7px;
    box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #e3e3e3;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;

    .proposalDetail {
      width: 100%;
      padding: 49px 20px 80px 20px;
    }

    .proposalHistory {
      min-width: unset;
      width: 100%;
    }
  }
`;

interface DetailProps {
  id: string;
}
const Detail: React.FC<DetailProps> = ({ id }) => {
  const { data: proposal } = useGetProposal({ id }, { enabled: !!id });

  const defaultValue = {
    result: [],
    sumVotes: new BigNumber(0),
  };

  const { data: forVoters = defaultValue } = useGetVoters(
    { id: id || "", support: true, limit: 100 },
    { enabled: !!id }
  );
  const { data: againstVoters = defaultValue } = useGetVoters(
    { id: id || "", support: false, limit: 100 },
    { enabled: !!id }
  );

  return (
    <Container>
      <BannerContainer>
        <BannerBackPattern>
          <img
            src={imgBackPattern}
            alt="backpattern"
            className="desktopImg"
          ></img>
          <img
            src={imgBackPatternMobile}
            alt="backpattern"
            className="mobileImg"
          ></img>
        </BannerBackPattern>
      </BannerContainer>
      <Content>
        <div className="topBar">
          <div className="back">
            <NavLink to="/governance">
              <img src={imgArrowLeft} alt="arrow" />
            </NavLink>
            <span>OVERVIEW</span>
          </div>
          {/* <div className="userInfo">
            <div className="userImg">
              <img src={imgUser} alt="user" />
            </div>
            <span>0xaaaa...bb</span>
          </div> */}
        </div>
        <div className="title">{proposal?.description.title}</div>
        <div className="status">
          {proposal?.state === "Successded" ? "Successful" : proposal?.state}
        </div>
        <div className="date">
          {proposal &&
          ["Defeated", "Expired", "Successded"].includes(proposal.state)
            ? proposal.endDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : proposal?.state === "Pending"
            ? proposal.createdDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : proposal?.state === "Active"
            ? proposal.startDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : proposal?.state === "Canceled"
            ? proposal.cancelDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : proposal?.state === "Queued"
            ? proposal.queuedDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : proposal?.executedDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
        </div>
        <VoteStatus>
          <div className="content">
            <div className="item">
              <span>For</span>
              <span className="totalWeight">
                {shortenValueWithSuffix({
                  value: forVoters.sumVotes.div(1e18),
                })}
              </span>
            </div>
            <div className="progressBar">
              <ProgressBar
                progress={
                  forVoters.sumVotes.plus(againstVoters.sumVotes).isEqualTo(0)
                    ? 0
                    : forVoters.sumVotes
                        .div(forVoters.sumVotes.plus(againstVoters.sumVotes))
                        .times(100)
                        .toNumber()
                }
              />
            </div>
            <div className="item">
              <span>{forVoters.result.length} Addresses</span>
              <span>Votes</span>
            </div>
            <div className="divider" />

            <div className="user_list">
              {forVoters.result.slice(0, 3).map((data, index) => (
                <div className="item" key={`for_${index}`}>
                  {/* <a
                    href={`${process.env.REACT_APP_BASE_URL}/#/governance/address/${data.address}`}
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <span>{truncateAddress(data.address)}</span>
                  {/* </a> */}
                  <span>
                    {shortenValueWithSuffix({
                      value: data.voteWeightWei.div(1e18),
                    })}
                  </span>
                </div>
              ))}
            </div>

            <div className="viewAll">
              <span>View All</span>
              <a
                href={`${process.env.REACT_APP_BASE_URL}/#/governance/proposal/${id}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={imgArrowRight} alt="arrow" />
              </a>
            </div>
          </div>

          <div className="content">
            <div className="item">
              <span>Against</span>
              <span className="totalWeight">0</span>
            </div>
            <div className="progressBar">
              <ProgressBar
                progress={
                  forVoters.sumVotes.plus(againstVoters.sumVotes).isEqualTo(0)
                    ? 0
                    : againstVoters.sumVotes
                        .div(forVoters.sumVotes.plus(againstVoters.sumVotes))
                        .times(100)
                        .toNumber()
                }
              />
            </div>
            <div className="item">
              <span>{againstVoters.result.length} Address</span>
              <span>Votes</span>
            </div>
            <div className="divider" />

            <div className="user_list">
              {againstVoters.result.slice(0, 3).map((data, index) => (
                <div className="item" key={`against_${index}`}>
                  {/* <a
                    href={`${process.env.REACT_APP_BASE_URL}/#/governance/address/${data.address}`}
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <span>{truncateAddress(data.address)}</span>
                  {/* </a> */}
                  <span>
                    {shortenValueWithSuffix({
                      value: data.voteWeightWei.div(1e18),
                    })}
                  </span>
                </div>
              ))}
            </div>

            <div className="viewAll">
              <span>View All</span>
              <a
                href={`${process.env.REACT_APP_BASE_URL}/#/governance/proposal/${id}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={imgArrowRight} alt="arrow" />
              </a>
            </div>
          </div>
        </VoteStatus>

        <ProposalDetail>
          <div className="proposalDetail">
            <div className="title">Details</div>
            <div className="divider" />
            <div className="sub-title">Description</div>
            <span>{proposal?.description.description}</span>
            <div className="sub-title" style={{ marginTop: "25px" }}>
              Operation
            </div>
            <div>
              {proposal?.actions.map((action) => (
                <ReadableActionSignature
                  key={`readable-action-signature-${action.signature}-${action.target}-${action.value}-${action.callData}`}
                  action={action}
                />
              ))}
            </div>
          </div>

          <div className="proposalHistory">
            <div className="title">Proposal History</div>
            <div className="divider" />

            <Stepper
              createdDate={proposal?.createdDate}
              startDate={proposal?.startDate}
              cancelDate={proposal?.cancelDate}
              queuedDate={proposal?.queuedDate}
              executedDate={proposal?.executedDate}
              endDate={proposal?.endDate}
              state={proposal?.state || "Active"}
            />
          </div>
        </ProposalDetail>
      </Content>
    </Container>
  );
};

export default Detail;
