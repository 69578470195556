import { BlackButton, PrimaryButtonA } from "components/common";
import { imgBackPatternXcn, imgCoinBase, imgOnyx } from "components/images";
import styled from "styled-components";

const BannerContainer = styled.div`
  background-color: #161a1f;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding: 0px 50px;
  }

  @media only screen and (max-width: 480px) {
    padding: 0px 24px;
  }
`;

const BannerBackPattern = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1069.8px;
  width: 100%;
  img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: unset;
    img {
      height: 768px;
      width: auto;
    }
  }
`;

const TopPage = styled.div`
  width: 100%;
  height: 768px;
  position: relative;
`;

const TopPageBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  max-width: 400px;
  width: 100%;
`;

const TopPageTitle = styled.div`
  font-size: 48px;
  text-align: center;
  color: #fff;
`;

const TopPageDetail = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`;

const TopPageButtonWrap = styled.div`
  margin-top: 35px;
  display: flex;
  gap: 15px;
`;

const BottomPage = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 117px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 153px;
  }
`;

const BottomPageBody = styled.div`
  max-width: 446px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const BottomPageTitle = styled.div`
  font-size: 30px;
  text-align: center;
  color: #fff;
`;

const BottomPageDetail = styled.div`
  margin-top: 30px;
  max-width: 320px;
  font-size: 21px;
  text-align: center;
  color: #fff;
`;

const BottomImgWrap = styled.div`
  margin: 85px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`;

const BannerPage = () => {
  return (
    <BannerContainer id="banner">
      <BannerBackPattern>
        <img src={imgBackPatternXcn} alt="backpattern"></img>
      </BannerBackPattern>
      <TopPage>
        <TopPageBody>
          <TopPageTitle>Onyxcoin (XCN)</TopPageTitle>
          <TopPageDetail>
            The Onyx Ecosystem Cryptocurrency. Stake your XCN today to
            participate in Onyx Governance.
          </TopPageDetail>
          <TopPageButtonWrap>
            <a
              href={`${process.env.REACT_APP_BASE_URL}/#/stake`}
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButtonA>Stake</PrimaryButtonA>
            </a>
            <a
              href={`${process.env.REACT_APP_BASE_URL}/#/governance`}
              target="_blank"
              rel="noreferrer"
            >
              <BlackButton>Vote</BlackButton>
            </a>
          </TopPageButtonWrap>
        </TopPageBody>
      </TopPage>
      {/* <BottomPage>
        <BottomPageBody>
          <BottomPageTitle>
            Get rewarded by completing learning modules about the Onyx
            Ecosystem.
          </BottomPageTitle>
          <BottomPageDetail>
            Coinbase recently launched a “Learn-to-Earn” program with #XCN.{" "}
          </BottomPageDetail>
          <BottomImgWrap>
            <img src={imgOnyx} alt="onyx" style={{ width: "228px" }} />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="29.817"
              viewBox="0 0 30 29.817"
            >
              <g data-name="Group 1182" transform="translate(-668 -1080.021)">
                <rect
                  data-name="Screen Shot 2023-01-07 at 3.49.53 PM"
                  width="41.392"
                  height=".776"
                  rx=".388"
                  transform="rotate(-135 578.489 410.51)"
                  style={{ fill: "#31d8ff" }}
                />
                <rect
                  data-name="Screen Shot 2023-01-07 at 3.49.53 PM"
                  width="41.392"
                  height=".776"
                  rx=".388"
                  transform="rotate(135 125.207 684.845)"
                  style={{ fill: "#31d8ff" }}
                />
              </g>
            </svg>

            <img src={imgCoinBase} alt="coinbase" />
            <a
              href="https://www.coinbase.com/learning-rewards"
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButtonA>Earn XCN on Coinbase today</PrimaryButtonA>
            </a>
          </BottomImgWrap>
        </BottomPageBody>
      </BottomPage> */}
    </BannerContainer>
  );
};

export default BannerPage;
