import { useEffect } from 'react';

export const useOutside = (ref: any, onClick: any) => {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClick();
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		// document.addEventListener("touchstart", handleClickOutside);
		return () => {
		  document.removeEventListener("mousedown", handleClickOutside);
		//   document.removeEventListener("touchstart", handleClickOutside);
		};
	}, [ref, onClick]);
};

export const useOutsideDropdown = (ref: any,refAgree : any, onClick: any) => {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if ((ref.current && !ref.current.contains(event.target))) {
				if(refAgree && !refAgree.current.contains(event.target))
					onClick();
				if(!refAgree) {
					onClick();
				}
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		// document.addEventListener("touchstart", handleClickOutside);
		return () => {
		  document.removeEventListener("mousedown", handleClickOutside);
		//   document.removeEventListener("touchstart", handleClickOutside);
		};
	}, [ref, refAgree,onClick]);
};