import {
  imgBackPattern,
  imgBackPatternMobile,
  imgOnyx,
} from "components/images";
import styled from "styled-components";

const BannerContainer = styled.div`
  background-color: #161a1f;
  position: relative;
  overflow: hidden;
  height: 589px;

  @media only screen and (max-width: 768px) {
    padding: 0px 50px;
    height: 100vh;
  }

  @media only screen and (max-width: 480px) {
    padding: 0px 24px;
  }
`;

const BannerBackPattern = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  max-width: 959px;
  width: 100%;

  .mobileImg {
    display: none;
  }

  img {
    width: 100%;
    object-position: 0px -100px;
  }

  @media only screen and (max-width: 768px) {
    .desktopImg {
      display: none;
    }

    .mobileImg {
      display: block;
    }

    width: 260vw;
    height: 80vh;
    left: -80vw;
    img {
      width: 260vw;
    }
  }
`;

const TopPage = styled.div`
  width: 100%;
  height: 589px;
  position: relative;
`;

const TopPageBody = styled.div`
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 414px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
  }
`;

const TopPageTitle = styled.div`
  font-size: 45px;
  text-align: center;
  color: #fff;

  @media only screen and (max-width: 768px) {
    font-size: 35px;
  }
`;

const TopPageDetail = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;

  img {
    width: 92px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

const BannerPage = () => {
  return (
    <BannerContainer id="banner">
      <BannerBackPattern>
        <img
          src={imgBackPattern}
          alt="backpattern"
          className="desktopImg"
        ></img>
        <img
          src={imgBackPatternMobile}
          alt="backpattern"
          className="mobileImg"
        ></img>
      </BannerBackPattern>
      <TopPage>
        <TopPageBody>
          <TopPageTitle>Onyx Liquidity Protocol</TopPageTitle>
          <TopPageDetail>
            Earn, Borrow, and Build with
            <img src={imgOnyx} alt="onyx" />
          </TopPageDetail>
        </TopPageBody>
      </TopPage>
    </BannerContainer>
  );
};

export default BannerPage;
