import Header from "components/header";
import Footer from "components/footer";
import Overview from "./overview";

const Governance = () => {
  return (
    <>
      <Header />
      <Overview />
      <Footer />
    </>
  );
};

export default Governance;
