import BigNumber from "bignumber.js";
import { useGetMarkets } from "clients/api";
import { ConfirmModal } from "components/ConfirmModal";
import { PrimaryButton } from "components/common";
import {
  imgUSDT,
  imgBTC,
  imgAPE,
  imgPUNK,
  imgBAYC,
  imgSHIBA,
  imgETH,
} from "components/images";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  background-color: #fff;
  padding: 300px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    padding: 350px 0px;
  }
`;

const AssetsLogoLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 101px;
    height: 101px;
  }

  .usdt {
    transform: translate(450px, -50%);
  }

  .ape {
    transform: translate(-550px, -144px);
  }

  .eth {
    transform: translate(-250px, -300px);
  }

  .btc {
    transform: translate(180px, -260px);
  }

  .punk {
    transform: translate(-400px, 130px);
  }

  .shiba {
    transform: translate(-100px, 160px);
  }

  .bayc {
    transform: translate(200px, 250px);
  }

  @media only screen and (max-width: 1280px) {
    .eth {
      transform: translate(-70px, -350px);
    }

    .btc {
      left: unset;
      right: 10%;
      transform: translate(0, -210px);
    }

    .punk {
      left: 5%;
      transform: translate(0, -220px);
    }

    .usdt {
      left: unset;
      right: 10%;
      transform: translate(0, 240px);
    }

    .ape {
      left: 6%;
      transform: translate(0, 240px);
    }

    .shiba {
      transform: translate(-80px, 180px);
    }

    .bayc {
      transform: translate(0, 320px);
    }
  }

  @media only screen and (max-width: 768px) {
    img {
      width: 69px;
      height: 69px;
    }
  }
`;

const AssetsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const AssetsSectionDetail = styled.div`
  font-size: 45px;
  color: #20252d;
  margin: 36px 0px 55px 0px;
  max-width: 666px;

  .value {
    color: #31d8ff;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 10px;
    font-size: 35px;
    max-width: unset;
  }
`;

const Assets = () => {
  const { data: getMarketsData } = useGetMarkets({
    placeholderData: {
      markets: [],
      dailyXcnWei: new BigNumber(0),
      marketVolumeLog: {
        totalSupplyUsd: new BigNumber(0),
        totalBorrowsUsd: new BigNumber(0),
        totalSupplyUsd24h: new BigNumber(0),
        totalBorrowsUsd24h: new BigNumber(0),
        blockNumber: 0,
      },
    },
  });

  const [confirmModalOpen, SetConfirmModalOpen] = useState(false);

  const onHandle = () => {
    const lastAgreeTime = localStorage.getItem("lastAgreeTime");
    if (Math.floor(Date.now() / 1000) > Number(lastAgreeTime) + 2592000)
      SetConfirmModalOpen(true);
    else window.open(process.env.REACT_APP_BASE_URL, "_self");
  };

  return (
    <Container>
      <AssetsLogoLayer>
        <img src={imgUSDT} className="usdt" alt="usdt" />
        <img src={imgAPE} className="ape" alt="ape" />
        <img src={imgETH} className="eth" alt="eth" />
        <img src={imgBTC} className="btc" alt="btc" />
        <img src={imgPUNK} className="punk" alt="punk" />
        <img src={imgSHIBA} className="shiba" alt="shiba" />
        <img src={imgBAYC} className="bayc" alt="bayc" />
      </AssetsLogoLayer>
      <AssetsSection id="assets">
        <AssetsSectionDetail>
          <span className="value">
            ${getMarketsData?.marketVolumeLog.totalBorrowsUsd.dp(2).toFormat()}
          </span>{" "}
          of borrowing backed by{" "}
          <span className="value">
            ${getMarketsData?.marketVolumeLog.totalSupplyUsd.dp(2).toFormat()}
          </span>{" "}
          of collateral markets
        </AssetsSectionDetail>
        <PrimaryButton style={{ minWidth: "170px" }}  onClick={() => onHandle()}>
          Launch App
        </PrimaryButton>
      </AssetsSection>
      <ConfirmModal
        isOpen={confirmModalOpen}
        handleClose={() => SetConfirmModalOpen(false)}
      />
    </Container>
  );
};

export default Assets;
