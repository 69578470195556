import { imgGovernance } from "components/images";
import { PrimaryButton } from "components/common";
import styled from "styled-components";

const Container = styled.div`
  background-color: white;
  overflow: hidden;
  position: relative;
  padding: 150px 0px;

  @media only screen and (max-width: 768px) {
    padding: 100px 0px;
  }
`;

const AppSection = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #20252d;

  @media only screen and (max-width: 768px) {
    img {
      width: 90%;
      margin-bottom: 90px;
    }
  }
`;

const PageTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: Roboto-Bold;
  text-align: center;

  @media only screen and (max-width: 768px) {
    width: 324px;
  }
`;

const PageSubTitle = styled.div`
  font-size: 16px;
  margin: 36px 0px 54px 0px;
  max-width: 360px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 15px;
    margin: 39px 0px 137px 0px;
  }
`;

const Community = () => {
  return (
    <Container>
      <AppSection>
        <PageTitle>Community Governance</PageTitle>
        <PageSubTitle>
          Onyx is controlled and operated by 11,010 Onyxcoin (XCN) holders and is
          fully decentralized.
        </PageSubTitle>
        <img src={imgGovernance} alt="app" />
        <PrimaryButton style={{ minWidth: "170px" }}>
          <a href="https://community.onyx.org" target="_blank">Governance Forum</a>
        </PrimaryButton>
      </AppSection>
    </Container>
  );
};

export default Community;
