import Header from "components/header";
import Footer from "components/footer";
import BannerPage from "./banner";
import Assets from "./assets";
import Market from "./market";
import Community from "./community";

const Home = () => {
  return (
    <>
      <Header />
      <BannerPage />
      <Assets />
      <Market />
      <Community />
      <Footer />
    </>
  );
};

export default Home;
