import React from "react";
import styled from "styled-components";
import { generateEthScanUrl } from "utilities";

import formatSignature from "./formatSignature";
import getContractName from "./getContractName";

const Signature = styled.div`
  color: ;
  overflow-wrap: anywhere;

  > a {
    color: #31d8ff;
    :hover {
      color: #31d8ff80;
    }
  }
`;

interface ReadableActionSignatureProps {
  action: any;
  className?: string;
}

export const ReadableActionSignature: React.FC<
  ReadableActionSignatureProps
> = ({ action, className }) => {
  return (
    <Signature key={`${action.signature}-${action.target}`}>
      <a
        href={generateEthScanUrl(action.target, "address")}
        target="_blank"
        rel="noreferrer"
      >
        {getContractName(action.target)}
      </a>
      {formatSignature(action)}
    </Signature>
  );
};
