import config from 'config';

export type UrlType = 'address' | 'tx';

export const generateEthScanUrl = <T extends UrlType = 'address'>(
  identifier: string,
  urlType?: T,
) => {
  const safeUrlType = urlType || 'address';

  let suffix: string = identifier;

  return `${config.ethScanUrl}/${safeUrlType}/${suffix}`;
};

export default generateEthScanUrl;
