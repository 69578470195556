import { NavLink } from "react-router-dom";
import { useWindowDimensions } from "hooks/useDimentions";
import { useOutside } from "hooks/useOutSide";
import { useRef, useState } from "react";
import styled from "styled-components";
import {
  PrimaryButton,
  DropDown,
  DropDownItemProps,
  HDivider,
  VDivider,
} from "./common";
import { imgOnyx } from "./images";
import { ConfirmModal } from "./ConfirmModal";

const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 99;
  background-color: #161a1f;
`;

const HeaderBody = styled.div`
  margin: 0px 32px 0px 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  min-height: 74px;

  @media only screen and (max-width: 768px) {
    margin: 0px 24px;
  }
`;

const NavGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  max-width: 900px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }
`;

const NavLinkGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 540px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 50px;
    max-width: unset;
  }
`;

const NavButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  @media only screen and (max-width: 768px) {
    flex-flow: row wrap;
    justify-content: center;
    gap: 50px;
  }
`;

const NavLinkOrg = styled(NavLink)`
  font-size: 17px;
  min-width: 50px;
  text-align: center;
  color: #fff;
  cursor: pointer;
`;

const NavLinkA = styled.a`
  font-size: 17px;
  min-width: 50px;
  text-align: center;
  color: #fff;
  cursor: pointer;
`;

const LangDropdownData: DropDownItemProps[] = [
  { label: "English", value: "english" },
];

const MobileMenuIcon = styled.div`
  svg {
    fill: #31d8ff;
    path {
      transition: all 0.5s;
    }
  }
`;

const MobilePopup = styled.div<{ isActive: boolean }>`
  padding: 100px 30px 60px 30px;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 76px;
  display: ${(props) => (props.isActive ? "flex" : "none")};
  z-index: 99;
  background-color: #161a1f;
  transition: all 1s;
  flex-direction: column;
  gap: 50px;
`;
const Header = () => {
  const [langState, setLangState] = useState<DropDownItemProps>({});
  const { isMobile } = useWindowDimensions();
  const [popupMenu, SetPopupMenu] = useState(false);
  const [confirmModalOpen, SetConfirmModalOpen] = useState(false);

  const mobilePopupRef = useRef(null);
  useOutside(mobilePopupRef, () => {
    SetPopupMenu(false);
  });

  const onHandle = () => {
    const lastAgreeTime = localStorage.getItem("lastAgreeTime");
    if (Math.floor(Date.now() / 1000) > Number(lastAgreeTime) + 2592000)
      SetConfirmModalOpen(true);
    else window.open(process.env.REACT_APP_BASE_URL, "_self");
  };
  return (
    <Container>
      <HeaderBody>
        <NavLinkOrg to="/">
          <img src={imgOnyx} alt="app-logo" width="110px" />
        </NavLinkOrg>
        {isMobile ? (
          <MobileMenuIcon
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              SetPopupMenu(!popupMenu);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23.879"
              height="18.773"
              viewBox="0 0 23.879 18.773"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_188"
                    data-name="Rectangle 188"
                    className="cls-1"
                    width="23.879"
                    height="18.773"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_126"
                data-name="Group 126"
                transform="translate(0 0)"
              >
                <g
                  id="Group_125"
                  data-name="Group 125"
                  className="cls-2"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_389"
                    data-name="Path 389"
                    className="cls-1"
                    d="M21.618,4.523H2.261A2.262,2.262,0,0,1,2.261,0H21.618a2.262,2.262,0,0,1,0,4.523"
                    transform={
                      popupMenu ? "translate(0 -11.554) " : "translate(0 0)"
                    }
                  />
                  <path
                    id="Path_390"
                    data-name="Path 390"
                    className="cls-1"
                    d="M21.618,23.2H2.261a2.262,2.262,0,0,1,0-4.523H21.618a2.262,2.262,0,0,1,0,4.523"
                    transform="translate(0 -11.554)"
                  />
                  <path
                    id="Path_391"
                    data-name="Path 391"
                    className="cls-1"
                    d="M21.618,41.882H2.261a2.262,2.262,0,0,1,0-4.523H21.618a2.262,2.262,0,0,1,0,4.523"
                    transform={
                      popupMenu
                        ? "translate(0 -11.554)"
                        : "translate(0 -23.109)"
                    }
                  />
                </g>
              </g>
            </svg>
          </MobileMenuIcon>
        ) : (
          <NavGroup>
            <NavLinkGroup>
              <NavLinkOrg to="/xcn">XCN</NavLinkOrg>
              <NavLinkOrg to="/governance">Governance</NavLinkOrg>
              <NavLinkA
                href={`${process.env.REACT_APP_BASE_URL}/#/markets`}
                target="_blank"
              >
                Markets
              </NavLinkA>
              <NavLinkA href="https://medium.com/OnyxProtocol" target="_blank">
                Blog
              </NavLinkA>
              <NavLinkA href="https://onyx.org/Whitepaper.pdf" target="_blank">
                White Paper
              </NavLinkA>
            </NavLinkGroup>
            <VDivider />
            <NavButtonGroup>
              <PrimaryButton onClick={() => onHandle()}>App</PrimaryButton>
              <DropDown
                data={LangDropdownData}
                onSelectedItem={setLangState}
                placeholder={LangDropdownData[0].label}
              ></DropDown>
            </NavButtonGroup>
          </NavGroup>
        )}
        <MobilePopup isActive={popupMenu} ref={mobilePopupRef}>
          <NavLinkGroup>
            <NavLinkOrg
              to="/xcn"
              onClick={(e) => {
                SetPopupMenu(false);
              }}
            >
              XCN
            </NavLinkOrg>
            <NavLinkOrg
              to="/governance"
              onClick={(e) => {
                SetPopupMenu(false);
              }}
            >
              Governance
            </NavLinkOrg>
            <NavLinkA
              href={`${process.env.REACT_APP_BASE_URL}/#/markets`}
              target="_blank"
              onClick={(e) => {
                SetPopupMenu(false);
              }}
            >
              Markets
            </NavLinkA>
            <NavLinkA
              href="https://medium.com/OnyxProtocol"
              target="_blank"
              onClick={(e) => {
                SetPopupMenu(false);
              }}
            >
              Blog
            </NavLinkA>
            <NavLinkA
              href="https://onyx.org/Whitepaper.pdf"
              target="_blank"
              onClick={(e) => {
                SetPopupMenu(false);
              }}
            >
              White Paper
            </NavLinkA>
          </NavLinkGroup>
          <VDivider />
          <NavButtonGroup>
            <PrimaryButton onClick={() => onHandle()}>App</PrimaryButton>
            <DropDown
              data={LangDropdownData}
              onSelectedItem={setLangState}
              placeholder={LangDropdownData[0].label}
            ></DropDown>
          </NavButtonGroup>
        </MobilePopup>
      </HeaderBody>
      <HDivider></HDivider>
      <ConfirmModal
        isOpen={confirmModalOpen}
        handleClose={() => SetConfirmModalOpen(false)}
      />
    </Container>
  );
};

export default Header;
