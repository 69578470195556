import { PrimaryButtonA } from "components/common";
import {
  imgOXCIcon,
  imgOXCIconMobile,
  imgUtilityIcon,
} from "components/images";
import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;
  padding: 50px 124px 90px 124px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    padding: 50px 50px 90px 50px;
  }

  @media only screen and (max-width: 480px) {
    padding: 50px 24px 90px 24px;
  }
`;

const OXCSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1100px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const OXCSectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const OXCSectionRight = styled.div`
  .desktop {
    img {
      width: 536px;
    }
  }
  .mobile {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .mobile {
      display: flex;
      img {
        width: 100%;
      }
    }
    .desktop {
      display: none;
    }
  }
`;

const OXCSectionTitle = styled.div`
  font-size: 36px;
  color: #20252d;
`;

const OXCSectionDetail = styled.div`
  font-size: 18px;
  color: #20252d;
  margin: 36px 0px 55px 0px;
  max-width: 474px;
  @media only screen and (max-width: 768px) {
    max-width: unset;
  }
`;

const UtilitySection = styled.div`
  margin-top: 100px;
  max-width: 1100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 100px;
  }
`;
const UtilitySectionLeft = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    max-width: 500px;
    width: 100%;
  }
`;

const UtilitySectionRight = styled.div``;

const UtilitySectionTitle = styled.div`
  font-size: 36px;
  color: #20252d;
`;

const UtilitySectionSubTitle = styled.div`
  font-size: 24px;
  color: #20252d;
  margin: 18px 0px;
`;

const UtilitySectionDetail = styled.div`
  font-size: 18px;
  color: #20252d;
  max-width: 540px;
  @media only screen and (max-width: 768px) {
    max-width: unset;
  }
`;

const Utility = () => {
  return (
    <Container>
      <OXCSection id="xcn">
        <OXCSectionRight>
          <div className="desktop">
            <img src={imgOXCIcon} alt="oxc" />
          </div>
          <div className="mobile">
            <img src={imgOXCIconMobile} alt="oxc" />
          </div>
        </OXCSectionRight>
        <OXCSectionLeft>
          <OXCSectionTitle>XCN</OXCSectionTitle>
          <OXCSectionDetail>
            Onyxcoin (XCN) is the governance and utility token for the Onyx
            Protocol designed to vote on protocol improvements and various
            community driven programs. XCN is also utilized for payments on the
            Onyx Liquidity Protocol based on parameters set by Onyx Governance.
          </OXCSectionDetail>
          <PrimaryButtonA>
            <a href="https://onyx.org/Whitepaper.pdf">Whitepaper</a>
          </PrimaryButtonA>
        </OXCSectionLeft>
      </OXCSection>
      <UtilitySection id="utility">
        <UtilitySectionLeft>
          <img src={imgUtilityIcon} alt="utility" />
        </UtilitySectionLeft>
        <UtilitySectionRight>
          <UtilitySectionTitle>Utility</UtilitySectionTitle>
          <UtilitySectionSubTitle>What is XCN used for?</UtilitySectionSubTitle>
          <UtilitySectionDetail>
            XCN has a variety of use case applications within the Onyx
            ecosystem, outside of voting on Onyx Protocol and Onyx DAO
            activities. Users can also stake XCN to secure the protocol and earn
            rewards. XCN is used as a payment method for the Onyx Liquidity
            Protocol with market speeds set by Onyx Governance.
          </UtilitySectionDetail>
        </UtilitySectionRight>
      </UtilitySection>
    </Container>
  );
};

export default Utility;
