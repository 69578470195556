enum FunctionKey {
  // Queries
  GET_PROPOSALS = "GET_PROPOSALS",
  GET_PROPOSAL = "GET_PROPOSAL",
  GET_VOTER_ACCOUNTS = "GET_VOTER_ACCOUNTS",
  GET_VOTER_DETAILS = "GET_VOTER_DETAILS",
  GET_VOTER_HISTORY = "GET_VOTER_HISTORY",
  GET_VOTERS = "GET_VOTERS",
  GET_MARKETS = "GET_MARKETS",
}

export default FunctionKey;
