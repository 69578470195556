import React from "react";

interface ProgressBarProps {
  progress: number;
}
const ProgressBar = ({ progress }: ProgressBarProps) => {
  const Parentdiv = {
    height: "5px",
    width: "100%",
    backgroundColor: "whitesmoke",
    borderRadius: "3px",
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: "#31d8ff",
    borderRadius: "3px",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv} />
    </div>
  );
};

export default ProgressBar;
