import BigNumber from "bignumber.js";
import { useGetProposals, useGetVoterAccounts } from "clients/api";
import { Icon } from "components/Icon";
import {
  imgBackPattern,
  imgBackPatternMobile,
  imgSuccess,
  imgArrowRight,
} from "components/images";
import { Spinner } from "components/Spinner";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { formatToReadablePercentage, truncateAddress } from "utilities";

const Container = styled.div`
  position: relative;
  background: #fcfcfc;
`;

const BannerContainer = styled.div`
  background-color: #161a1f;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 589px;

  @media only screen and (max-width: 768px) {
    padding: 0px 50px;
    height: 100vh;
  }

  @media only screen and (max-width: 480px) {
    padding: 0px 24px;
  }
`;

const BannerBackPattern = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  max-width: 959px;
  width: 100%;

  .mobileImg {
    display: none;
  }

  img {
    width: 100%;
    object-position: 0px -100px;
  }

  @media only screen and (max-width: 768px) {
    .desktopImg {
      display: none;
    }

    .mobileImg {
      display: block;
    }

    width: 260vw;
    height: 80vh;
    left: -80vw;
    img {
      width: 260vw;
    }
  }
`;

const Content = styled.div`
  position: relative;
  padding: 150px 156px;
  z-index: 10;

  .title {
    font-size: 45px;
    color: white;
  }

  @media only screen and (max-width: 1024px) {
    padding: 150px 50px;
  }

  @media only screen and (max-width: 768px) {
    padding: 230px 35px 150px 35px;

    .title {
      font-size: 35px;
    }
  }
`;

const RecentProposal = styled.div`
  margin-top: 94px;
  padding: 39px 39px 39px 36px;
  border-radius: 7px;
  box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #e3e3e3;
  background-color: white;
  color: #20252d;

  .title {
    font-family: Roboto-Bold;
    font-size: 25px;
    color: #20252d;
  }

  .proposalItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 17px 0 11px;
    padding: 18px 37px 15px 25px;
    border-radius: 7px;
    border: solid 1px #eaeaea;
    gap: 30px;
    font-size: 20px;

    .inner {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .secondRow {
      margin-top: 10px;
      width: 300px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .date {
        font-size: 16px;
      }
    }

    .secondRowMobile {
      display: none;
    }

    .closeIcon {
      height: 9px;
      width: 9px;
    }

    .markIcon {
      height: 9px;
      width: 12px;
    }

    .iconContainer {
      height: 36px;
      width: 36px;
      flex-shrink: 0;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 24px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    .errorIconContainer {
      background-color: #e93d44;
      border: 1px solid #e93d44;
    }

    .markIconContainer {
      background-color: #31d8ff;
      border: 1px solid #31d8ff;
    }

    .dotsIconContainer {
      background-color: white;
      border: 1px solid #31d8ff;
    }
  }

  .viewAll {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 16px;
    gap: 22px;
  }

  @media only screen and (max-width: 768px) {
    .proposalItem {
      flex-direction: column;
      font-size: 15px;
      margin: 17px 0 8px;
      padding: 22px 25px 17px 13px;
      gap: 22px;

      .secondRow {
        display: none;
      }

      .secondRowMobile {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
    }
  }
`;

const VoterRank = styled.div`
  margin-top: 71px;
  padding: 39px 39px 25px 36px;
  border-radius: 7px;
  box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #e3e3e3;
  background-color: white;
  color: #20252d;

  .title {
    font-family: Roboto-Bold;
    font-size: 25px;
    color: #20252d;
  }

  .table-header {
    margin-top: 46px;
    display: grid;
    grid-template-columns: 1.5fr 1.2fr 1fr 1.2fr;
    margin-bottom: 10px;

    span:not(:first-child) {
      text-align: right;
    }
  }

  .table-content {
    padding: 10px 0px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }

  .table-row {
    display: grid;
    grid-template-columns: 1.5fr 1.2fr 1fr 1.2fr;
    padding: 10px 0px;
    font-size: 16px;

    span:not(:first-child) {
      text-align: right;
    }
  }

  .viewAll {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 16px;
    gap: 22px;
  }

  @media only screen and (max-width: 768px) {
    padding: 39px 15px 25px 15px;

    .title {
      width: 206px;
    }

    .table-header,
    .table-row {
      grid-template-columns: 1.5fr 1fr 1fr;
      span:last-child {
        display: none;
      }
    }
  }
`;

const Overview = () => {
  const {
    data: { proposals } = { proposals: [] },
    isFetching: isGetProposalsFetching,
  } = useGetProposals({ page: 0, limit: 3 });

  const {
    data: { voterAccounts } = {
      voterAccounts: [],
    },
    isFetching: isGetVoterAccountsFetching,
  } = useGetVoterAccounts({ page: 0, limit: 10 });

  return (
    <Container>
      <BannerContainer>
        <BannerBackPattern>
          <img
            src={imgBackPattern}
            alt="backpattern"
            className="desktopImg"
          ></img>
          <img
            src={imgBackPatternMobile}
            alt="backpattern"
            className="mobileImg"
          ></img>
        </BannerBackPattern>
      </BannerContainer>
      <Content>
        <div className="title">Governance Overview</div>
        <RecentProposal>
          <div className="title">Recent Proposals</div>
          {isGetProposalsFetching && <Spinner />}

          {proposals.map((proposal) => (
            <NavLink
              to={`/governance/proposal/${proposal.id}`}
              key={proposal.id}
            >
              <div className="proposalItem">
                <div className="inner">
                  <div>
                    <div>{proposal.description.title}</div>
                    <div className="secondRow">
                      <span>{proposal.state}</span>
                      <span className="date">
                        {["Defeated", "Expired", "Successded"].includes(
                          proposal.state
                        )
                          ? proposal.endDate?.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : proposal.state === "Pending"
                          ? proposal.createdDate?.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : proposal.state === "Active"
                          ? proposal.startDate?.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : proposal.state === "Canceled"
                          ? proposal.cancelDate?.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : proposal.state === "Queued"
                          ? proposal.queuedDate?.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : proposal.executedDate?.toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                      </span>
                    </div>
                  </div>
                  {["Canceled", "Defeated", "Expired"].includes(
                    proposal.state
                  ) ? (
                    <span className="iconContainer errorIconContainer">
                      <Icon name="close" className="closeIcon" />
                    </span>
                  ) : proposal.state === "Pending" ? (
                    <span className="iconContainer dotsIconContainer">
                      <Icon name="dots" className="markIcon" />
                    </span>
                  ) : (
                    <span className="iconContainer markIconContainer">
                      <Icon name="mark" className="markIcon" />
                    </span>
                  )}
                </div>
                <div className="secondRowMobile">
                  <span>{proposal.state}</span>
                  <span className="date">
                    {["Defeated", "Expired", "Successded"].includes(
                      proposal.state
                    )
                      ? proposal.endDate?.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : proposal.state === "Pending"
                      ? proposal.createdDate?.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : proposal.state === "Active"
                      ? proposal.startDate?.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : proposal.state === "Canceled"
                      ? proposal.cancelDate?.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : proposal.state === "Queued"
                      ? proposal.queuedDate?.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : proposal.executedDate?.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                  </span>
                </div>
              </div>
            </NavLink>
          ))}

          <div className="viewAll">
            <span>View All Proposals</span>
            <a
              href={`${process.env.REACT_APP_BASE_URL}/#/governance`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={imgArrowRight} alt="arrow" />
            </a>
          </div>
        </RecentProposal>
        <VoterRank>
          <div className="title">Top Addresses by Voting Weight</div>
          {isGetVoterAccountsFetching && <Spinner />}
          <div className="table-header">
            <span>Rank</span>
            <span>Votes</span>
            <span>Vote Weight</span>
            <span>Proposals Voted</span>
          </div>
          <div className="table-content">
            {voterAccounts.map((voter, index) => (
              <div className="table-row" key={index}>
                <span>
                  {index + 1}&nbsp;&nbsp;&nbsp;{truncateAddress(voter.address)}
                </span>
                <span>
                  {new BigNumber(voter.chnStake).div(1e18).dp(4).toFormat()}
                </span>
                <span>
                  {formatToReadablePercentage(voter.voteWeightPercent)}
                </span>
                <span>{voter.proposalsVoted}</span>
              </div>
            ))}
          </div>
          <div className="viewAll">
            <span>View Leaderboard</span>
            <a
              href={`${process.env.REACT_APP_BASE_URL}/#/governance/leaderboard`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={imgArrowRight} alt="arrow" />
            </a>
          </div>
        </VoterRank>
      </Content>
    </Container>
  );
};

export default Overview;
