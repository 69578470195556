import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOutside, useOutsideDropdown } from 'hooks/useOutSide';

// ## Color ##
// Primary Color : 31d8ff
// Black Color   : 161a1f
export const PrimaryButton = styled.div`
  user-select: none;
  min-width: 108px;
  min-height: 42px;
  padding: 12px 0px;
  object-fit: contain;
  border-radius: 5px;
  background-color: #31d8ff;
  cursor: pointer;
  font-size: 17px;
  text-align: center;
  color: #fff;
  &:hover {
    background-color: #00c6f3;
  }
  &:active {
    background-color: #198da1;
  }
`;

export const PrimaryButtonA = styled.div`
  user-select: none;
  min-width: 108px;
  min-height: 42px;
  padding: 12px 37px;
  object-fit: contain;
  border-radius: 5px;
  background-color: #31d8ff;
  cursor: pointer;
  font-size: 17px;
  text-align: center;
  color: #fff;
  &:hover {
    background-color: #00c6f3;
  }
  &:active {
    background-color: #198da1;
  }
`;

export const BlackButton = styled.div`
  user-select: none;
  min-width: 108px;
  min-height: 42px;
  padding: 12px 37px;
  object-fit: contain;
  border-radius: 5px;
  background-color: #161a1f;
  font-size: 17px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #22272e;
  }
  &:active {
    background-color: #10131a;
  }
`;

export const HDivider = styled.div`
  height: 2px;
  background-color: #21252b;
`;

export const VDivider = styled.div`
  width: 2px;
  min-height: 24px;
  background-color: #21252b;
  @media only screen and (max-width: 1080px) {
    display: none;
  }
`;

export interface DropdownProps {
  defaultIndex: number;
  dropList: any;
  OnCurrentIndexChanged: any;
}

const DropdownBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  color: #fff;
  position: relative;
`;

const StyledDropdown = styled.div`
  position: relative;
`;

const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  line-height: 22px;
  color: #f5f5f5;
  border-radius: 2px;
  padding: 8px 16px;
  cursor: pointer;
  justify-content: space-between;
`;

const StyledPopup = styled.div`
  width: 100%;
  min-width: 150px;
  position: absolute;
  z-index: 20;
  background: rgba(48, 48, 48, 0.98);
  box-shadow: 0px 7px 80px rgba(0, 0, 0, 0.07),
    0px 2.92443px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 1.56354px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 0.876509px 10.0172px rgba(0, 0, 0, 0.035),
    0px 0.465507px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 0.193708px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 2px;
  top: 50px;
  right: 0px;
`;

const StyledItem = styled.div`
  color: #ffffff;
  padding: 16px 22px;
  background: #282828fe;
  cursor: pointer;
  &:hover {
    background: #3e3e3e;
  }
  &.active {
    font-weight: 600;
    background: #3e3e3e;
  }
`;

const StyledDropdownPlaceholder = styled.div<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? '#f5f5f5' : 'rgba(245,245,245,0.7)')};
`;

export type DropDownItemProps = {
  value?: string;
  label?: string;
};

interface DropDownProps {
  data: Array<DropDownItemProps>;
  placeholder?: string;
  onSelectedItem?: (item: DropDownItemProps) => void;
  isSelected?: boolean;
}

export const DropDown: React.FC<DropDownProps> = ({
  data,
  placeholder,
  onSelectedItem,
  isSelected,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DropDownItemProps>(
    placeholder === '' ? data[0] : {label:placeholder}
  );

  const popupRef = useRef(null);
  const wrapRef = useRef(null);
  useOutsideDropdown(popupRef, wrapRef, setShowPopup);

  useEffect(() => {
    setSelectedItem(placeholder === '' ? data[0] : {label:placeholder});
  }, [placeholder]);

  const selectItem = (item: any) => {
    onSelectedItem?.(item);
    setSelectedItem(item);
    setShowPopup(!showPopup);
  };

  return (
    <StyledDropdown>
      <StyledWrap
        ref={wrapRef}
        onMouseUp={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setShowPopup(!showPopup);
        }}
      >
        {selectedItem == null && (
          <StyledDropdownPlaceholder isActive={isSelected ? isSelected : false}>
            {placeholder}
          </StyledDropdownPlaceholder>
        )}
        {selectedItem != null && selectedItem.label}
       
          <div style = { showPopup ? {transform: 'rotate(180deg)', transition:'all 0.5s'} : {transform: 'rotate(0deg)', transition:'all 0.5s'}}>

          <svg xmlns="http://www.w3.org/2000/svg" width="10.884" height="5.984" viewBox="0 0 10.884 5.984">
              <defs>
                  <clipPath id="0qicmx1p3a">
                      <path data-name="Rectangle 77" style={{fill:'#fff'}} d="M0 0h5.984v10.884H0z"/>
                  </clipPath>
              </defs>
              <g data-name="Group 139">
                  <g data-name="Group 41" transform="rotate(90 5.442 5.442)" style={{clipPath:'#0qicmx1p3a'}}>
                      <path data-name="Path 106" d="M.908.144 5.824 5.06a.529.529 0 0 1-.748.748L.16.892a.516.516 0 0 1 0-.748.557.557 0 0 1 .748 0" style={{fill:'#fff'}}/>
                      <path data-name="Path 107" d="M.16 5.076 5.076.16a.516.516 0 0 1 .748 0 .516.516 0 0 1 0 .748L.908 5.824a.516.516 0 0 1-.748 0 .516.516 0 0 1 0-.748" transform="translate(0 4.9)" style={{fill:'#fff'}}/>
                  </g>
              </g>
          </svg>
          </div>
        
      </StyledWrap>
      {showPopup && (
        <StyledPopup ref={popupRef}>
          {data.map((item: any, index: number) => {
            return (
              <StyledItem
                key={index}
                className={item.value === selectedItem?.value ? 'active' : ''}
                onClick={() => {
                  selectItem(item);
                }}
              >
                {item.label}
              </StyledItem>
            );
          })}
        </StyledPopup>
      )}
    </StyledDropdown>
  );
};
