import React from "react";

import styled from "styled-components";
import { Spinner as SpinnerAnimation } from "../LottieAnimation";
import TEST_IDS from "./testIds";

interface SpinnerProps {
  variant?: "large" | "small";
  autoplay?: boolean;
  className?: string;
}

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;

  .spinner {
    height: 50px;
    width: 50px;

    svg path {
      stroke: #31d8ff !important;
    }
  }
`;

export const Spinner: React.FC<SpinnerProps> = ({
  variant = "large",
  autoplay = true,
}) => {
  return (
    <Container data-testid={TEST_IDS.spinner}>
      <SpinnerAnimation autoplay={autoplay} className="spinner" />
    </Container>
  );
};
