import { useParams } from "react-router-dom";
import Header from "components/header";
import Footer from "components/footer";
import Detail from "./detail";

const GovernanceDetail = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Header />
      <Detail id={id ? id : "0"} />
      <Footer />
    </>
  );
};

export default GovernanceDetail;
