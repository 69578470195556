import React, { useMemo } from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

type ProposalState =
  | "Pending"
  | "Active"
  | "Canceled"
  | "Defeated"
  | "Successded"
  | "Queued"
  | "Expired"
  | "Executed";

export interface StepperProps {
  className?: string;
  createdDate: Date | undefined;
  startDate: Date | undefined;
  cancelDate: Date | undefined;
  queuedDate: Date | undefined;
  executedDate: Date | undefined;
  endDate: Date | undefined;
  state: ProposalState;
}

const getActiveStepIndex = (proposalState: ProposalState) => {
  switch (proposalState) {
    case "Pending":
      return 1;
    case "Active":
      return 2;
    case "Defeated":
    case "Successded":
    case "Canceled":
    case "Expired":
      return 3;
    case "Queued":
      return 4;
    default:
      return 5;
  }
};

const Root = styled.div`
  display: flex;
  flex-direction: column;

  .step {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      flex-direction: row;
      max-width: initial;
    }
  }

  .labelAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  .labelText {
    font-weight: 600;
    padding-left: 18px;

    @media only screen and (max-width: 768px) {
      position: initial;
      padding-left: 18px;
      width: initial;
    }
  }

  .labelText.disable {
    color: #888;
  }

  .dateDefault {
    align-self: center;
    white-space: nowrap;
    margin-left: 12px;
  }

  .closeIcon {
    height: 9px;
    width: 9px;
  }

  .markIcon {
    height: 9px;
    width: 12px;
  }

  .iconContainer {
    height: 36px;
    width: 36px;
    flex-shrink: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 24px;

    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  .errorIconContainer {
    background-color: #E93D44;
    border: 1px solid #E93D44;
  }

  .markIconContainer {
    background-color: #31d8ff;
    border: 1px solid #31d8ff;
  }

  .numberIconContainer {
    background-color: transparent;
    border: 1px solid #888;
    color: #888;
  }

  .connector {
    border-left: 1px solid #31d8ff;
    height: 24px;
    width: 0;
    margin-left: 17.5px;

    @media only screen and (max-width: 768px) {
      border-bottom: none;
      border-left: 1px solid #31d8ff;
      height: 24px;
      width: 0;
      margin-left: 17.5px;
      margin-top: 0;
      flex: initial;
    }
  }
`;

const Stepper: React.FC<StepperProps> = ({
  createdDate,
  startDate,
  cancelDate,
  queuedDate,
  executedDate,
  endDate,
  state,
}) => {
  const steps = useMemo(
    () => [
      {
        getLabel: () => "Created",
        getTimestamp: () => createdDate,
        completedIcon: () => (
          <span className="iconContainer markIconContainer">
            <Icon name="mark" className="markIcon" />
          </span>
        ),
      },
      {
        getLabel: () => "Active",
        getTimestamp: () => startDate,
        completedIcon: () => (
          <span className="iconContainer markIconContainer">
            <Icon name="mark" className="markIcon" />
          </span>
        ),
      },
      {
        getLabel: () => {
          switch (state) {
            case "Canceled":
              return "Canceled";
            case "Defeated":
              return "Defeated";
            case "Expired":
              return "Expired";
            default:
              return "Succeed";
          }
        },
        getTimestamp: () => {
          if (state === "Canceled") {
            return cancelDate;
          }

          if (state === "Pending" || state === "Active") {
            return undefined;
          }

          return endDate;
        },
        completedIcon: () => {
          if (
            state === "Canceled" ||
            state === "Defeated" ||
            state === "Expired"
          ) {
            return (
              <span className="iconContainer errorIconContainer">
                <Icon name="close" className="closeIcon" />
              </span>
            );
          }
          return (
            <span className="iconContainer markIconContainer">
              <Icon name="mark" className="markIcon" />
            </span>
          );
        },
      },
      {
        getLabel: () => "Queue",
        getTimestamp: () => queuedDate,
        completedIcon: () => (
          <span className="iconContainer markIconContainer">
            <Icon name="mark" className="markIcon" />
          </span>
        ),
      },
      {
        getLabel: () => "Execute",
        getTimestamp: () => executedDate,
        completedIcon: () => (
          <span className="iconContainer markIconContainer">
            <Icon name="mark" className="markIcon" />
          </span>
        ),
      },
    ],
    [
      createdDate,
      startDate,
      cancelDate,
      queuedDate,
      executedDate,
      endDate,
      state,
    ]
  );
  const activeStepIndex = getActiveStepIndex(state);
  return (
    <Root>
      {steps.map((step, idx) => {
        const completed = activeStepIndex > idx;
        return (
          <React.Fragment key={step.getLabel()}>
            <div className="step">
              <div className="labelAndIcon">
                {completed ? (
                  step.completedIcon()
                ) : (
                  <span className="iconContainer numberIconContainer">
                    {idx + 1}
                  </span>
                )}
                <span className={completed ? "labelText" : "labelText disable"}>{step.getLabel()}</span>
              </div>
              {step.getTimestamp() && (
                <span className="dateDefault">
                  {step.getTimestamp()?.toLocaleDateString("en-CA")}
                </span>
              )}
            </div>
            {idx + 1 !== steps.length && <div className="connector" />}
          </React.Fragment>
        );
      })}
    </Root>
  );
};

export default Stepper;
