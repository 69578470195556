import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "pages/home";
import Governance from "pages/governance";
import GovernanceDetail from "pages/governance/detail";
import Xcn from "pages/xcn";

const Links = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/xcn" element={<Xcn />} />
        <Route path="/governance" element={<Governance />} />
        <Route path="/governance/proposal/:id" element={<GovernanceDetail />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Links;
