import { unexpectedErrorPhrases } from "./unexpectedErrorPhrases";

export interface VErrorParamMap {
  unexpected: { message: string } | undefined;
}

export interface VErrorPhraseMap {
  unexpected: keyof typeof unexpectedErrorPhrases;
}

export type ErrorCodes = keyof VErrorParamMap;

export class VError<E extends ErrorCodes> extends Error {
  data: VErrorParamMap[E] | undefined;

  type: E;

  code: VErrorPhraseMap[E];

  constructor({
    type,
    code,
    data,
  }: {
    type: E;
    code: VErrorPhraseMap[E];
    data?: VErrorParamMap[E];
  }) {
    super(code);
    this.type = type;
    this.code = code;
    this.data = data;
  }
}
