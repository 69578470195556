import Header from 'components/header';
import Footer from 'components/footer';
import BannerPage from './banner';
import Utility from './utility';
import Distribution from './distribution';

const Xcn = () => {
  return (
    <>
      <Header />
      <BannerPage />
      <Utility />
      <Distribution/>
      <Footer />
    </>
  );
};

export default Xcn;
