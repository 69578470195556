import Modal from "react-modal";
import { Icon } from "./Icon";
import { PrimaryButton } from "./common";
import { useState } from "react";

export interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const customStyles = {
  overlay: {
    backgroundColor: "#000000A0",
    zIndex: "100",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#161A1F",
    width: "320px",
    border: "0px",
  },
};

export const ConfirmModal: React.FC<ModalProps> = ({ isOpen, handleClose }) => {
  const [value, setValue] = useState(false);

  const onAgree = () => {
    if (value)
      localStorage.setItem(
        "lastAgreeTime",
        Math.floor(Date.now() / 1000).toString()
      );
    window.open(process.env.REACT_APP_BASE_URL, "_self");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Confirm Modal"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
          borderBottom: "1px solid #757575",
          marginBottom: "15px",
        }}
      >
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>Launch App</div>
        <div
          onClick={handleClose}
          style={{
            display: "flex",
            cursor: "pointer",
          }}
        >
          <Icon name="close" />
        </div>
      </div>

      <div style={{ fontSize: "17px" }}>
        You are leaving onyx.org and will be redirected to a third party,
        independent website.
        <br />
        <br />
        The website is a community deployed and a maintained instance of the
        open source{" "}
        <a
          href="https://github.com/Onyx-Protocol/onyx-interface"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline", color: "#31d8ff" }}
        >
          Onyx Front End
        </a>
        , hosted and served on the distributed, peer-to-peer{" "}
        <a
          href="https://ipfs.io/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline", color: "#31d8ff" }}
        >
          IPFS network
        </a>
        .<br />
        <br />
        By clicking Agree you accept the{" "}
        <a
          href="https://docs.onyx.org/terms-of-service"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline", color: "#31d8ff" }}
        >
          T&Cs
        </a>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          marginTop: "10px",
          gap: "5px",
        }}
      >
        <input
          type="checkbox"
          checked={value}
          onChange={(e) => setValue(e.target.checked)}
          style={{ accentColor: "#31d8ff", margin: "0px", marginTop: "3px" }}
        />
        <label
          style={{ cursor: "pointer" }}
          onClick={() => {
            setValue(!value);
          }}
        >
          Don't show this message again for 30 days.
        </label>
      </div>

      <PrimaryButton style={{ marginTop: "15px" }} onClick={() => onAgree()}>
        Agree
      </PrimaryButton>
    </Modal>
  );
};
