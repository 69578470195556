import {
  imgBackPatternXcn,
  imgPurchaseBittrex,
  imgPurchaseCoinbase,
  imgPurchaseKraken,
  imgPurchaseKucoin,
} from "components/images";
import styled from "styled-components";

const Container = styled.div`
  background-color: #161a1f;
  overflow: hidden;
  position: relative;
`;

const BackBigPattern = styled.div`
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1083px;
  width: 100%;
  z-index: 0;
  img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: unset;
    img {
      height: 900px;
      width: auto;
    }
  }
`;

const BackSmallPattern = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 780px;
  width: 100%;
  z-index: 0;
  img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    max-width: unset;
    width: unset;
    img {
      height: 900px;
      width: auto;
    }
  }
`;

const DistribSection = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PageTitle = styled.div`
  margin-top: 65px;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
`;

const SectionTitle = styled.div`
  font-size: 36px;
  color: #fff;
`;

const PageSubTitle = styled.div`
  font-size: 24px;
  margin: 17px 0px 70px 0px;
  color: #fff;
  max-width: 480px;
  text-align: center;
`;

const PageDetail = styled.div`
  font-size: 18px;
  color: #fff;
`;

const CardGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
  flex-flow: row wrap;
`;

const Card = styled.div`
  max-width: 364px;
  padding: 30px 43px 65px 40px;
  border-radius: 7px;
  box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.2);
  background-color: #161a1f;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  min-height: 680px;
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
`;
const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardRow = styled.div``;

const CardLabel = styled.div`
  font-size: 18px;
  color: #fff;
`;

const CardText = styled.div`
  font-size: 18px;
  margin-top: 13px;
  color: #fff;
`;

const CardWallet = styled.div`
  margin-top: 72px;
  font-size: 16px;
  color: #31d8ff;
`;

const DistribUpdates = styled.div`
  font-size: 18px;
  color: #fff;
  margin-top: 50px;
`;

const PurchaseSection = styled.div`
  padding: 162px 44px 286px 44px;

  position: relative;
`;

const PurchaseBody = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 77px;
`;

const PurchaseGroup = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    gap: 70px;
    flex-direction: column;
  }
`;

const Distribution = () => {
  return (
    <Container id="dao">
      <BackBigPattern>
        <img src={imgBackPatternXcn} alt="back" />
      </BackBigPattern>
      <DistribSection>
        <PageTitle>Distribution</PageTitle>
        <PageSubTitle>
          The circulating supply of XCN is: 23,692,085,401 The total supply of
          XCN is: 48,470,523,779
        </PageSubTitle>
        <CardGroup>
          <Card>
            <CardTop>
              <SectionTitle>Treasury</SectionTitle>
              <CardRow>
                <CardLabel>Amount:</CardLabel>
                <CardText>15,000,000,000 XCN</CardText>
              </CardRow>
              <CardRow>
                <CardLabel>Details:</CardLabel>
                <CardText>
                  The Onyx DAO was granted 15 billion XCN to utilize on
                  advancing blockchain and web3 initiatives to create open
                  finance opportunities via the Onyx Protocol. They are locked
                  inside a time lock smart contract with monthly releases of
                  200,000,000 XCN per month.
                </CardText>
              </CardRow>
            </CardTop>
            <CardBottom>
              <CardRow>
                <CardLabel>Months:</CardLabel>
                <CardText>75</CardText>
              </CardRow>
              <CardWallet>
                <a
                  href="https://etherscan.io/token/0xA2cd3D43c775978A96BdBf12d733D5A1ED94fb18?a=0x8f6ffec54c1e569c63992eb2dc4fae84e3792551"
                  target="_blank"
                  rel="noreferrer"
                >
                  0x8f6ffec54c1e…..fae84e3792551
                </a>
              </CardWallet>
            </CardBottom>
          </Card>

          <Card>
            <CardTop>
              <SectionTitle>DAO</SectionTitle>
              <CardRow>
                <CardLabel>Amount:</CardLabel>
                <CardText>9,778,438,377</CardText>
              </CardRow>
              <CardRow>
                <CardLabel>Details:</CardLabel>
                <CardText>
                  The DAO Treasury allocation is under the control of Onyx
                  Governance Stakers who control these funds through community
                  driven initiatives. The monthly budget passed on the last
                  community constitution is 200,000,000 XCN
                </CardText>
              </CardRow>
            </CardTop>
            <CardBottom>
              <CardRow>
                <CardLabel>Months:</CardLabel>
                <CardText>50</CardText>
              </CardRow>
              <CardWallet>
                <a
                  href="https://etherscan.io/token/0xA2cd3D43c775978A96BdBf12d733D5A1ED94fb18?a=0x28ca9caae31602d0312ebf6466c9dd57fca5da93"
                  target="_blank"
                  rel="noreferrer"
                >
                  0x28ca9caae316…..9dd57fca5da93
                </a>
              </CardWallet>
            </CardBottom>
          </Card>
        </CardGroup>
        <DistribUpdates>Last Updated: 3/07/2023</DistribUpdates>
      </DistribSection>
      <PurchaseSection id="blog">
        <BackSmallPattern>
          <img src={imgBackPatternXcn} alt="back" />
        </BackSmallPattern>
        <PurchaseBody>
          <SectionTitle>Purchase XCN</SectionTitle>
          <PurchaseGroup>
            <img
              src={imgPurchaseCoinbase}
              style={{ width: "131.7px" }}
              alt="a"
            />
            <img src={imgPurchaseKraken} style={{ width: "117.9px" }} alt="a" />
            <img src={imgPurchaseKucoin} style={{ width: "121.7px" }} alt="a" />
            <img
              src={imgPurchaseBittrex}
              style={{ width: "142.6px" }}
              alt="a"
            />
          </PurchaseGroup>
        </PurchaseBody>
      </PurchaseSection>
    </Container>
  );
};

export default Distribution;
