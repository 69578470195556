import BigNumber from "bignumber.js";
import { Market, MarketVolumeLog } from "types";
import { restService } from "utilities";

export interface GetMarketsResponse {
  dailyXcn: number;
  markets: Market[];
  request: { addresses: string[] };
  xcnRate: string;
  marketVolumeLog: MarketVolumeLog;
}

export interface GetMarketsOutput {
  markets: Market[];
  marketVolumeLog: MarketVolumeLog;
  dailyXcnWei: BigNumber | undefined;
}

const getMarkets = async (): Promise<GetMarketsOutput> => {
  const response = await restService<GetMarketsResponse>({
    endpoint: "/xcn",
    method: "GET",
  });
  if ("result" in response && response.result === "error") {
    throw new Error(response.message);
  }
  let markets: Market[] = [];
  let dailyXcnWei;
  let marketVolumeLog: MarketVolumeLog = {
    totalSupplyUsd: new BigNumber(0),
    totalBorrowsUsd: new BigNumber(0),
    totalSupplyUsd24h: new BigNumber(0),
    totalBorrowsUsd24h: new BigNumber(0),
    blockNumber: 0,
  };

  if (response && response.data && response.data.data) {
    dailyXcnWei = new BigNumber(response.data.data.dailyXcn);

    marketVolumeLog.totalSupplyUsd = new BigNumber(response.data.data.marketVolumeLog.totalSupplyUsd);
    marketVolumeLog.totalBorrowsUsd = new BigNumber(response.data.data.marketVolumeLog.totalBorrowsUsd);
    marketVolumeLog.totalSupplyUsd24h = new BigNumber(response.data.data.marketVolumeLog.totalSupplyUsd24h);
    marketVolumeLog.totalBorrowsUsd24h = new BigNumber(response.data.data.marketVolumeLog.totalBorrowsUsd24h);
    marketVolumeLog.blockNumber = response.data.data.marketVolumeLog.blockNumber;
  }
  return { markets, dailyXcnWei, marketVolumeLog };
};

export default getMarkets;
