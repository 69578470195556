import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { imgOnyx } from "./images";
import { zipMediaPack } from "./media";

const Container = styled.div`
  background-color: #0f1215;
  padding: 80px 46px 100px 46px;
  display: flex;
  justify-content: space-between;
  gap: 100px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 100px;
  }
`;

const FooterLogoWrap = styled.div`
  svg {
    cursor: pointer;
    fill: #353d48;
  }
  svg:hover {
    fill: #fff;
  }
`;

const FooterLogoText = styled.div`
  font-size: 15px;
  margin-top: 14px;
  color: #4d5d72;
`;

const FooterIconLinks = styled.div`
  display: flex;
  margin-top: 33px;
  gap: 24px;
`;

const FooterLinksWrap = styled.div`
  max-width: 845px;
  width: 100%;
`;

const FooterLinksGroup = styled.div`
  max-width: 717px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  @media only screen and (max-width: 768px) {
    gap: 50px 30px;
  }
`;

const LinkRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media only screen and (max-width: 768px) {
    flex-basis: 30%;
  }
`;

const LinkTitle = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  color: #4d5d72;
  margin-bottom: 16px;
`;

const FooterLink = styled.a`
  font-size: 16px;
  color: #353d48;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    color: #fff;
  }
`;

const Footer = () => {
  return (
    <Container>
      <FooterLogoWrap>
        <NavLink to="/">
          <img src={imgOnyx} alt="logo" width="85px" />
        </NavLink>
        <FooterLogoText>©2023 Onyx</FooterLogoText>
        <FooterIconLinks>
          <FooterLink href="https://twitter.com/OnyxProtocol" target="_blank">
            <svg
              data-name="Group 753"
              xmlns="http://www.w3.org/2000/svg"
              width="15.128"
              height="12.294"
              viewBox="0 0 15.128 12.294"
            >
              <defs>
                <clipPath id="yesha643ra">
                  <path data-name="Rectangle 1229" d="M0 0h15.128v12.295H0z" />
                </clipPath>
              </defs>
              <g data-name="Group 752">
                <path
                  data-name="Path 5373"
                  d="M4.758 12.295a8.771 8.771 0 0 0 8.831-8.831c0-.134 0-.268-.009-.4a6.315 6.315 0 0 0 1.549-1.607 6.2 6.2 0 0 1-1.782.488A3.116 3.116 0 0 0 14.711.227a6.229 6.229 0 0 1-1.971.754 3.107 3.107 0 0 0-5.29 2.831A8.813 8.813 0 0 1 1.053.568a3.106 3.106 0 0 0 .961 4.143 3.073 3.073 0 0 1-1.406-.388v.039A3.1 3.1 0 0 0 3.1 7.406a3.117 3.117 0 0 1-.819.11 3.082 3.082 0 0 1-.581-.057 3.107 3.107 0 0 0 2.9 2.156 6.225 6.225 0 0 1-3.859 1.328A6.376 6.376 0 0 1 0 10.9a8.788 8.788 0 0 0 4.758 1.394"
                />
              </g>
            </svg>
          </FooterLink>

          <FooterLink href="https://t.me/OnyxOrg" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.904"
              height="12.294"
              viewBox="0 0 14.904 12.294"
            >
              <defs>
                <clipPath id="ektkh9rc7a">
                  <path data-name="Rectangle 1230" d="M0 0h14.904v12.294H0z" />
                </clipPath>
              </defs>
              <g data-name="Group 755">
                <g data-name="Group 754">
                  <path
                    data-name="Path 5374"
                    d="M14.875.264a.319.319 0 0 0-.217-.236 1.113 1.113 0 0 0-.588.042S1.012 4.763.267 5.283c-.16.112-.214.176-.24.253a.438.438 0 0 0 .273.533l3.366 1.1a.371.371 0 0 0 .171-.01c.765-.484 7.7-4.864 8.1-5.011.064-.019.111 0 .1.046-.16.562-6.185 5.918-6.185 5.918a.323.323 0 0 0-.038.062h-.008L5.49 11.5s-.131 1.023.892 0A28.914 28.914 0 0 1 8.15 9.885c1.158.8 2.4 1.683 2.94 2.145a.937.937 0 0 0 .682.263c.51-.019.653-.58.653-.58S14.8 2.137 14.884.853c.008-.125.018-.206.02-.292a1.086 1.086 0 0 0-.029-.3"
                  />
                </g>
              </g>
            </svg>
          </FooterLink>
        </FooterIconLinks>
      </FooterLogoWrap>
      <FooterLinksWrap>
        <FooterLinksGroup>
          <LinkRow>
            <LinkTitle>OnyX</LinkTitle>
            <FooterLink href="https://onyx.org/xcn">Onyxcoin</FooterLink>
            <FooterLink
              href={`${process.env.REACT_APP_BASE_URL}/#/governance`}
              target="_blank"
            >
              DAO
            </FooterLink>
            <FooterLink href="https://medium.com/OnyxProtocol" target="_blank">
              Blog
            </FooterLink>
            <FooterLink href="https://onyx.org/Whitepaper.pdf" target="_blank">
              White Paper
            </FooterLink>
            <FooterLink href={process.env.REACT_APP_BASE_URL} target="_blank">
              DApp
            </FooterLink>
            <FooterLink href="https://community.onyx.org" target="_blank">
              Forums
            </FooterLink>
          </LinkRow>
          <LinkRow>
            <LinkTitle>Exchanges</LinkTitle>
            <FooterLink
              href="https://pro.coinbase.com/trade/XCN-USDT"
              target="_blank"
            >
              Coinbase
            </FooterLink>
            <FooterLink
              href="https://trade.kraken.com/markets/kraken/xcn/usd"
              target="_blank"
            >
              Kraken
            </FooterLink>
            <FooterLink
              href="https://www.bitfinex.com/t/XCN:USDT"
              target="_blank"
            >
              Bitfinex
            </FooterLink>
            <FooterLink
              href="https://www.bithumb.com/trade/order/XCN_KRW"
              target="_blank"
            >
              Bithumb
            </FooterLink>
            <FooterLink
              href="https://www.kucoin.com/trade/XCN-USDT"
              target="_blank"
            >
              Kucoin
            </FooterLink>
            <FooterLink href="https://gate.io/trade/xcn_usdt" target="_blank">
              Gate
            </FooterLink>
            <FooterLink
              href="https://www.huobi.com/en-us/exchange/xcn_usdt"
              target="_blank"
            >
              Huobi
            </FooterLink>
            <FooterLink
              href="https://bittrex.com/Market/Index?MarketName=USDT-XCN"
              target="_blank"
            >
              Bittrex
            </FooterLink>
            <FooterLink
              href="https://droidex.io" 
              target="_blank"
            >
              Droidex.io
            </FooterLink>
          </LinkRow>
          <LinkRow>
            <LinkTitle>Community</LinkTitle>
            <FooterLink href="https://twitter.com/OnyxProtocol" target="_blank">
              Twitter
            </FooterLink>
            <FooterLink href="https://t.me/OnyxOrg" target="_blank">
              Telegram
            </FooterLink>
            <FooterLink href="https://t.me/OnyxAnnouncements " target="_blank">
              Telegram News
            </FooterLink>
            <FooterLink href="https://medium.com/OnyxProtocol" target="_blank">
              Medium
            </FooterLink>
            <FooterLink href="https://discord.com/invite/onyxprotocol" target="_blank">
              Discord
            </FooterLink>
          </LinkRow>
          <LinkRow>
            <LinkTitle>Developers</LinkTitle>
            <FooterLink href="https://GitHub.com/Onyx-Protocol" target="_blank">
              GitHub
            </FooterLink>
            <FooterLink href="https://docs.onyx.org" target="_blank">
              Documentation
            </FooterLink>
            {/* <FooterLink href="https://docs.onyx.org/api" target="_blank">
              API Library
            </FooterLink>
            <FooterLink href="https://docs.onyx.org/sdk" target="_blank">
              SDK
            </FooterLink> */}
            <FooterLink href="https://bridge.onyx.org" target="_blank">
              Bridge
            </FooterLink>
            <FooterLink href={zipMediaPack} download="Onyx-Media-Pack" target="_blank">
              Media pack
            </FooterLink>
          </LinkRow>
        </FooterLinksGroup>
      </FooterLinksWrap>
    </Container>
  );
};

export default Footer;
