import { imgBackPattern, imgApp, imgCertik } from "components/images";
import styled from "styled-components";

const Container = styled.div`
  background-color: #161a1f;
  overflow: hidden;
  position: relative;
  padding: 50px 0px;

  @media only screen and (max-width: 768px) {
    padding: 100px 0px;
  }
`;

const BackBigPattern = styled.div`
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1083px;
  width: 100%;
  z-index: 0;
  img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: unset;
    img {
      display: none;
    }
  }
`;

const AppSection = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    img {
      width: 90%;
    }
  }
`;

const PageTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-family: Roboto-Bold;
  color: #fff;
  text-align: center;

  @media only screen and (max-width: 768px) {
    width: 240px;
  }
`;

const PageSubTitle = styled.div`
  font-size: 25px;
  margin: 12px 0px 65px 0px;
  color: #fff;
  max-width: 480px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 15px;
    margin: 38px 0px 108px 0px;
  }
`;

const AuditSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #686e77;
  font-size: 20px;
  margin: 27px 0px 20px 0px;

  img {
    width: 150px;
  }

  @media only screen and (max-width: 768px) {
    margin: 108px 0px 20px 0px;
  }
`;

const Market = () => {
  return (
    <Container>
      <BackBigPattern>
        <img src={imgBackPattern} alt="back" />
      </BackBigPattern>
      <AppSection>
        <PageTitle>Multi-Token Type Money Market</PageTitle>
        <PageSubTitle>Supports ETH, ERC-20, ERC-721 and ERC-1155</PageSubTitle>
        <img src={imgApp} alt="app" />
      </AppSection>
      <AuditSection>
        <div>Audited by</div>
        <img src={imgCertik} alt="certik" />
      </AuditSection>
    </Container>
  );
};

export default Market;
